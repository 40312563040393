import { OpenGraphMedia } from 'next-seo/lib/types';
import { ImageAPIResult } from '../types/api/commonTypes';
import { GeneralCMSResult } from '../types/cms/generalTypes';
import { SEOCMSResult } from './../types/cms/generalTypes';
import { OPEN_GRAPH_DEFAULT_HEIGHT, OPEN_GRAPH_DEFAULT_WIDTH } from './constants';
import { getImageFromApiURl } from './http';

export function apiImageToOGImage(image: ImageAPIResult, alt: string): OpenGraphMedia {
  return { url: getImageFromApiURl(image.name, OPEN_GRAPH_DEFAULT_WIDTH), alt, width: OPEN_GRAPH_DEFAULT_WIDTH, height: OPEN_GRAPH_DEFAULT_HEIGHT };
}

export function getOGImagesFromCMSData(pageData: { SEO: SEOCMSResult }, generalData: GeneralCMSResult, alt?: string): OpenGraphMedia[] {
  const ogImage = pageData.SEO.OGImage?.data?.attributes || generalData.DefaultOGImage?.data?.attributes;
  if (!ogImage) {
    return [];
  }
  return [{ url: ogImage.url, alt: alt || pageData.SEO.Title, width: OPEN_GRAPH_DEFAULT_WIDTH, height: OPEN_GRAPH_DEFAULT_HEIGHT }];
}
