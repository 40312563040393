import { DAWACityResult, DAWAMunicipalityResult } from '../../types/dawa';
import { loadCities, loadMunicipalities, loadPostalCodesByMunicipalityCode } from '../../utils/http';
import { GroupLabels, LocationSearchParams, LocationSuggestionOption, LocationSuggestionOptionGroup } from './types';

const VIEWPORT_WIDTH = 0.14;
const VIEWPORT_HEIGHT = 0.08;

function getAverageCoordsAndBox(coords: { x: number; y: number }[]): {
  center: { x: number; y: number };
  bbox: { northEast: { x: number; y: number }; southWest: { x: number; y: number } };
} {
  const sumOfCoords = coords.reduce<{ x: number; y: number }>(
    (accumulator, current) => ({ x: accumulator.x + current.x, y: accumulator.y + current.y }),
    { x: 0, y: 0 },
  );
  const middleCoords = {
    x: sumOfCoords.x / coords.length,
    y: sumOfCoords.y / coords.length,
  };
  const bbox = coords.reduce<{ northEast: { x: number; y: number }; southWest: { x: number; y: number } }>(
    (accumulator, current) => ({
      northEast: {
        x: Math.max(accumulator.northEast.x, current.x),
        y: Math.max(accumulator.northEast.y, current.y),
      },
      southWest: {
        x: Math.min(accumulator.southWest.x, current.x),
        y: Math.min(accumulator.southWest.y, current.y),
      },
    }),
    {
      northEast: { x: middleCoords.x + VIEWPORT_WIDTH / 2, y: middleCoords.y + VIEWPORT_HEIGHT / 2 },
      southWest: { x: middleCoords.x - VIEWPORT_WIDTH / 2, y: middleCoords.y - VIEWPORT_HEIGHT / 2 },
    },
  );
  return {
    center: middleCoords,
    bbox,
  };
}

function groupCitiesByPostalCode(cities: DAWACityResult[]): LocationSuggestionOption[] {
  const groupedCities = cities.reduce<{ [cityName: string]: DAWACityResult[] }>((accumulator, current) => {
    const {
      postnummer: { navn },
    } = current;
    const existingEntry = accumulator[navn];
    if (existingEntry) {
      return { ...accumulator, [navn]: [...existingEntry, current] };
    }
    return { ...accumulator, [navn]: [current] };
  }, {});
  return Object.entries(groupedCities)
    .map(([cityName, cityResults]) => {
      const numericPostalCodes = cityResults.map<number>((cityResult) => Number(cityResult.postnummer.nr));
      const coords = getAverageCoordsAndBox(
        cityResults.map((cityResult) => ({ x: cityResult.postnummer.visueltcenter_x, y: cityResult.postnummer.visueltcenter_y })),
      );
      const postalcode = String(
        numericPostalCodes.length > 1 ? `${Math.min(...numericPostalCodes)}-${Math.max(...numericPostalCodes)}` : numericPostalCodes[0],
      );

      return {
        label: `${postalcode} ${cityName}`,
        value: {
          city: cityName,
          zipCode: numericPostalCodes.join(','),
          coords,
        },
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
}

async function getZipCodesByMunicipality(municipalityCode: string): Promise<string> {
  const cities = await loadPostalCodesByMunicipalityCode(municipalityCode);
  const zipCodesForCity = cities.reduce<string[]>((accumulator, current) => {
    const { nr } = current;
    return accumulator.find((currentZipCode: string) => currentZipCode === nr) ? accumulator : [...accumulator, nr];
  }, []);
  return zipCodesForCity.join(',');
}

async function getMunicipalities(municipalities: DAWAMunicipalityResult[], searchTerm: string): Promise<LocationSuggestionOption[]> {
  return await Promise.all(
    municipalities
      .filter((municipality) => municipality.kommune.navn.toLowerCase().includes(searchTerm.toLowerCase()))
      .map(
        async ({
          kommune: {
            navn,
            region,
            visueltcenter: [centerX, centerY],
            bbox: [southWestX, southWestY, northEastX, northEastY],
            kode,
          },
        }) =>
          ({
            label: navn,
            value: {
              region: region.navn,
              coords: {
                center: {
                  x: centerX,
                  y: centerY,
                },
                bbox: {
                  northEast: {
                    x: northEastX,
                    y: northEastY,
                  },
                  southWest: {
                    x: southWestX,
                    y: southWestY,
                  },
                },
              },
              zipCode: await getZipCodesByMunicipality(kode),
            },
          }) as LocationSuggestionOption,
      ),
  );
}

export function getResultsFunction(
  labels?: GroupLabels,
  citiesOnly: boolean = false,
): (searchTerm: string) => Promise<LocationSuggestionOptionGroup[]> {
  return async (searchTerm: string) => {
    const cities = groupCitiesByPostalCode(await loadCities(searchTerm));
    if (citiesOnly) {
      return [
        {
          options: cities.map((city) => ({ ...city, label: city.label.replace(/^[0-9]{4} /, '') })),
        },
      ];
    }
    const municipalities = await getMunicipalities(await loadMunicipalities(searchTerm), searchTerm);

    return [
      {
        label: labels?.municipality || 'Municipality', // TODO - Needs translation from Strapi
        options: municipalities,
      },
      {
        label: labels?.city || 'City', // TODO - Needs translation from Strapi
        options: cities,
      },
    ];
  };
}

export function getSearchParamsFromOption(option: LocationSuggestionOption): LocationSearchParams {
  const {
    label,
    value: { city, zipCode, region, coords },
  } = option;

  if (!coords) {
    return {
      location: { city, zipCode, region, label },
    };
  }

  const {
    center,
    bbox: { northEast, southWest },
  } = coords;

  return {
    center: { coordinates: [center.x, center.y] },
    northEast: {
      coordinates: [northEast.x, northEast.y],
    },
    southWest: {
      coordinates: [southWest.x, southWest.y],
    },
    location: { city, zipCode, region, label },
  };
}

export function getOptionFromSearchParams(searchParams: LocationSearchParams): LocationSuggestionOption {
  if (!searchParams.center || !searchParams.northEast || !searchParams.southWest) {
    const {
      location: { city, zipCode, region, label },
    } = searchParams;
    return {
      label,
      value: {
        city,
        zipCode,
        region,
      },
    };
  }
  const {
    center: {
      coordinates: [centerX, centerY],
    },
    northEast: {
      coordinates: [northEastX, northEastY],
    },
    southWest: {
      coordinates: [southWestX, southWestY],
    },
    location: { city, zipCode, region, label },
  } = searchParams;

  return {
    label,
    value: {
      city,
      zipCode,
      region,
      coords: {
        center: {
          x: centerX,
          y: centerY,
        },
        bbox: {
          northEast: {
            x: northEastX,
            y: northEastY,
          },
          southWest: {
            x: southWestX,
            y: southWestY,
          },
        },
      },
    },
  };
}
