import { useCallback, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getResultsFunction } from './functions';
import { getStyles } from './styles';
import { GroupLabels, LocationSuggestionOption, LocationSuggestionOptionGroup } from './types';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';

const SELECT_ID = 'selectId';

type Style = 'default' | 'rounded';

interface LocationSuggestionBoxProps {
  initialQuery?: LocationSuggestionOption;
  onChange: (option: LocationSuggestionOption | null) => void;
  placeholder?: string;
  style: Style;
  loadingMessage?: string;
  groupLabels?: GroupLabels;
  citiesOnly?: boolean;
  disabled?: boolean;
}

const LocationSuggestionBox = ({
  initialQuery,
  onChange,
  placeholder,
  style,
  loadingMessage,
  groupLabels,
  citiesOnly,
  disabled,
}: LocationSuggestionBoxProps) => {
  const [searchQuery, setSearchQuery] = useState<LocationSuggestionOption | null>();
  const { theme } = useWhiteLabel();
  const { defaultStyles, roundedStyles } = getStyles(theme);

  const getResults = useCallback(() => getResultsFunction(groupLabels, citiesOnly), [groupLabels, citiesOnly])();

  useEffect(() => {
    setSearchQuery(initialQuery || null);
  }, [initialQuery]);

  return (
    <AsyncSelect<LocationSuggestionOption, false, LocationSuggestionOptionGroup>
      loadOptions={getResults}
      value={disabled ? null : searchQuery}
      onChange={(option: LocationSuggestionOption | null) => {
        setSearchQuery(option);
        onChange(option);
      }}
      isClearable
      instanceId={SELECT_ID}
      id={SELECT_ID}
      styles={style === 'rounded' ? roundedStyles : defaultStyles}
      placeholder={placeholder}
      loadingMessage={() => loadingMessage}
      noOptionsMessage={() => null}
      isDisabled={disabled}
    />
  );
};

export default LocationSuggestionBox;
