import { NextSeo } from 'next-seo';
import { OpenGraphMedia } from 'next-seo/lib/types';
import { useRouter } from 'next/router';
import { useTranslatedRoutes } from '../hooks/useTranslatedRoutes';
import { ResourceLanguagePath } from '../types/common';
import { useWhiteLabel } from 'providers/WhiteLabelProvider';

const REMOVE_TRAILING_SLASH_PATTERN = /\/+$/;

function removeTrailingSlash(url: string) {
  return url.replace(REMOVE_TRAILING_SLASH_PATTERN, '');
}

const createUrl = (
  propstepUrl: string,
  locale: string | undefined,
  route: string | undefined,
  pageNumber?: string | string[],
  view?: string | string[],
) => {
  const urlWithPageNumber =
    !pageNumber || pageNumber === '1' ? `${propstepUrl}${locale}${route}` : `${propstepUrl}${locale}${route}?page=${pageNumber}`;
  return view ? `${urlWithPageNumber}&view=${view}` : urlWithPageNumber;
};

interface SEOProps {
  title: string;
  description?: string;
  images: OpenGraphMedia[];
  languagePaths?: ResourceLanguagePath[];
  noIndex?: boolean;
}

const SEO = ({ title, description, images, languagePaths, noIndex = false }: SEOProps) => {
  const { locale, query } = useRouter();
  const pageNumber = query.page;
  const routes = useTranslatedRoutes(languagePaths);
  const currentRoute = routes.find((route) => route.locale === locale);
  const propstepUrl = 'https://propstep.com/';
  const url = createUrl(propstepUrl, locale, currentRoute?.route, pageNumber, query.view);
  const urlWithoutTrailingSlash = removeTrailingSlash(url);
  const { name: whiteLabelCompanyName } = useWhiteLabel();
  const whiteLabelTitle = title.replace('Propstep', whiteLabelCompanyName);

  return (
    <NextSeo
      title={whiteLabelTitle}
      description={description}
      canonical={urlWithoutTrailingSlash}
      languageAlternates={routes.map((route) => {
        const url = createUrl(propstepUrl, route.locale, route.route, pageNumber, query.view);
        return {
          hrefLang: route.locale,
          href: removeTrailingSlash(url),
        };
      })}
      noindex={noIndex}
      nofollow={noIndex}
      openGraph={{
        type: 'website',
        url: urlWithoutTrailingSlash,
        title,
        description,
        images,
      }}
    />
  );
};

export default SEO;
