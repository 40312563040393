import styled from 'styled-components';
import { FAQsCMSResult } from '../../types/cms/homepageTypes';
import Container from '../../sections/common/Container';
import Accordion from '../../components/Accordion';
import { Heading } from 'legacy-ui';

const StyledFAQs = styled.section`
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 128px 0;
  }
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    flex-direction: row;
  }
`;

const StyledFAQsHeading = styled.div`
  margin-bottom: 32px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    flex: 0 0 380px;
    margin-right: 112px;
    margin-bottom: 0;
  }
`;

const StyledFAQsAccordion = styled.div`
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    flex: 1 1 auto;
  }
`;

const FAQs = ({ fromCMS }: { fromCMS?: FAQsCMSResult }) => {
  return (
    <Container>
      <StyledFAQs>
        <StyledFAQsHeading>
          <Heading size="r" tag="h3">
            {fromCMS?.Headline}
          </Heading>
        </StyledFAQsHeading>
        <StyledFAQsAccordion>
          <Accordion
            items={fromCMS?.Questions.map((question) => ({
              headline: question.Headline,
              description: question.Description,
            }))}
          />
        </StyledFAQsAccordion>
      </StyledFAQs>
    </Container>
  );
};

export default FAQs;
