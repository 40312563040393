import React, { Ref, useEffect, useRef, useState } from 'react';
import Container from '../common/Container';
import { Heading, Text, styled } from 'legacy-ui';
import businessBackground from '../../assets/business-background.svg';
import Image from 'next/image';
import { BusinessCMSResult } from '../../types/cms/homepageTypes';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ContainerWrapper = styled.div`
  overflow: hidden;
  > div {
    max-width: 1348px;
    padding: 0 16px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      padding: 0 45px;
    }
  }
  padding-bottom: 105px;
`;

interface StyledImageProps {
  containerWidth: number;
}

const StyledImage = styled.div<StyledImageProps>`
  position: absolute;
  z-index: 1;
  > span {
    position: initial !important;
    > img {
      height: auto !important;
      min-height: none !important;
      max-height: none !important;
    }
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  > h4 {
    margin-bottom: 28px;
    font-weight: 500;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      font-weight: 600;
    }
  }
  > h3 {
    margin-bottom: 18px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    @media only screen and (min-width: 1110px) {
      margin-bottom: 14px;
      font-weight: 600;
      font-size: 56px;
      line-height: 68px;
    }
  }
  > p {
    max-width: 915px;
    margin-bottom: 28px;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    }
  }
`;

interface ContentWrapperProps {
  containerWidth: number;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  min-height: calc((${(props) => props.containerWidth}px * 2) + 800px);
  padding: 80px 27px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 50px 35px;
    min-height: calc((${(props) => props.containerWidth}px * 0.55) + 515px);
  }
`;

const CustomButton = styled.button`
  background-color: ${(props) => props.theme.color.orange40};
  color: white;
  padding: 12px 18px;
  width: 237px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    margin-bottom: 0;
    padding: 15px 35px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    width: 308px;
  }
`;

const BackGroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  object-fit: fill;
  height: 100%;
  width: 100%;
  img {
    border-radius: 8px;
  }
`;

const createStyledImage = (
  width: string,
  top: string,
  topOffset: string,
  left: string,
  right: string,
  mdWidth: string,
  mdTop: string,
  mdTopOffset: string,
  mdLeft: string,
  mdRight: string,
) => {
  return styled(StyledImage)`
    width: ${width};
    top: calc((${(props) => props.containerWidth}px * ${top} + ${topOffset}));
    right: ${right};
    left: ${left};
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      width: ${mdWidth};
      top: calc((${(props) => props.containerWidth}px * ${mdTop} + ${mdTopOffset}));
      left: ${mdLeft};
      right: ${mdRight};
    }
  `;
};

const StyledImage1 = createStyledImage('98%', '0.1', '470px', '-15px', 'auto', '49%', '0.1', '450px', '-35px', 'auto');
const StyledImage2 = createStyledImage('43%', '0.5', '530px', '-10px', 'auto', '22%', '0.1', '520px', '48.5%', 'auto');
const StyledImage3 = createStyledImage('61%', '0.5', '500px', 'auto', '-15px', '30%', '0.1', '400px', 'auto', '-35px');
const StyledImage4 = createStyledImage('72%', '1.7', '590px', 'auto', '-20px', '34%', '0.38', '480px', '-25px', 'auto');
const StyledImage5 = createStyledImage('82%', '1', '580px', '10px', 'auto', '40%', '0.38', '540px', '38%', 'auto');
const StyledImage6 = createStyledImage('26%', '1.5', '580px', '10px', 'auto', '14%', '0.3', '510px', 'auto', '5%');

function useContainerWidth() {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setContainerWidth(width);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { containerRef, containerWidth };
}

interface BusinessOverviewProps {
  fromCMS?: BusinessCMSResult;
}

const ANIMATION_TYPE = 'fade-up';
const ANIMATION_OFFSET = '-100';

const BusinessOverview = React.forwardRef(({ fromCMS }: BusinessOverviewProps, ref: Ref<HTMLDivElement>) => {
  const { containerRef, containerWidth } = useContainerWidth();
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    if (containerWidth) {
      Aos.refresh();
    }
  }, [containerWidth]);

  const IMAGE_COMPONENTS = [
    { StyledImage: StyledImage1 },
    { StyledImage: StyledImage2 },
    { StyledImage: StyledImage3 },
    { StyledImage: StyledImage4 },
    { StyledImage: StyledImage5, offset: -200 },
    { StyledImage: StyledImage6 },
  ];

  const Images = fromCMS?.Images.data.map((value, index) => {
    return {
      src: value.attributes.url,
      alt: value.attributes.alternativeText,
      StyledImage: IMAGE_COMPONENTS[index].StyledImage,
      offset: IMAGE_COMPONENTS[index].offset || ANIMATION_OFFSET,
    };
  });

  return (
    <ContainerWrapper ref={ref}>
      <Container>
        <ContentWrapper ref={containerRef} containerWidth={containerWidth}>
          <BackGroundImage>
            <Image src={businessBackground} alt="BusinessBackground" layout="fill" objectFit="fill" />
          </BackGroundImage>
          <HeadingWrapper>
            <Heading size="xxs" tag="h4">
              {fromCMS?.Title}
            </Heading>
            <Heading size="l" tag="h3">
              {fromCMS?.Headline}
            </Heading>
            <Text size="r">{fromCMS?.Description}</Text>
            <CustomButton onClick={() => window.open(fromCMS?.BusinessButtonLink, '_self')}>{fromCMS?.BusinessButton}</CustomButton>
          </HeadingWrapper>
          {Images &&
            Images.map(({ src, alt, StyledImage, offset }, index) => (
              <StyledImage key={index} data-aos={ANIMATION_TYPE} containerWidth={containerWidth} data-aos-offset={offset}>
                <Image src={src} alt={alt} layout="fill" objectFit="fill" priority />
              </StyledImage>
            ))}
        </ContentWrapper>
      </Container>
    </ContainerWrapper>
  );
});

export default BusinessOverview;
