import Image from 'next/image';
import styled from 'styled-components';
import { Heading } from 'legacy-ui';
import Container from '../common/Container';
import { HeaderCMSResult } from '../../types/cms/homepageTypes';
import { Text } from 'legacy-ui';

const StyledHero = styled.section`
  display: flex;
  position: relative;
  color: white;
  background-color: ${(props) => props.theme.color.gray90};
  padding: 0 38px 0 38px;
  height: 88vh;
  align-items: center;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    padding: 0 88px 0 88px;
  }
`;

const StyledHeroImage = styled.div`
  width: 100%;
  height: 105%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    height: 108%;
  }
`;

const StyledHeroContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 3;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 868px;
  margin-top: 28px;
  h1 {
    text-align: left;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      font-weight: 600;
      font-size: 68px;
      line-height: 75px;
      margin-bottom: 20px;
    }
  }
  h2 {
    margin-bottom: 28px;
    font-size: 13px;
    line-height: 22px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      margin-bottom: 46px;
      font-size: 16px;
      line-height: 28px;
    }
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    flex-direction: row;
  }
`;

const CustomButton = styled.button`
  background-color: ${(props) => props.theme.color.green100};
  padding: 12px 18px;
  width: 212px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  border-radius: 4px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    margin-bottom: 0;
    margin-right: 20px;
    padding: 15px 35px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    width: 308px;
  }
`;

const ButtonGreen = styled(CustomButton)`
  background-color: transparent;
  border: 1px solid #fff;
`;

const HeadingPart = styled.span`
  color: ${(props) => props.theme.color.orange20};
`;

interface HeaderProps {
  fromCMS?: HeaderCMSResult;
  scrollToBusinessSection: () => void;
  scrollToSearchSection: () => void;
}

const Header = ({ fromCMS, scrollToBusinessSection, scrollToSearchSection }: HeaderProps) => {
  return (
    <StyledHero>
      {fromCMS && (
        <StyledHeroImage>
          <Image src={fromCMS?.Image.data.attributes.url} alt="Propstep" layout="fill" objectFit="cover" priority />
        </StyledHeroImage>
      )}
      <StyledHeroContent>
        <Container>
          <HeadingWrapper>
            <Heading size="xl" tag="h1">
              {fromCMS?.Headline} <HeadingPart>{fromCMS?.HeadlinePart}</HeadingPart>
            </Heading>
            <Text size="lg" tag="h2">
              {fromCMS?.Description}
            </Text>
          </HeadingWrapper>
          <ButtonWrapper>
            <CustomButton onClick={scrollToBusinessSection}>{fromCMS?.BusinessButton}</CustomButton>
            <ButtonGreen onClick={scrollToSearchSection}>{fromCMS?.SearchButton}</ButtonGreen>
          </ButtonWrapper>
        </Container>
      </StyledHeroContent>
    </StyledHero>
  );
};

export default Header;
