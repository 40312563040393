import { WhiteLabelTheme } from 'types/api/whiteLabel';

export const getStyles = (theme: WhiteLabelTheme) => {
  const defaultStyles = {
    control: (provided: any, state: { isDisabled: boolean }) => ({
      ...provided,
      height: '52px',
      border: `1px solid ${theme.color.textFieldBorderColor}`,
      backgroundColor: state.isDisabled ? theme.color.textFieldBackgroundColorActive : 'white',
      padding: '5px 7px',
      borderRadius: '8px',
      boxShadow: 'none',
      zIndex: '2',
      cursor: 'text',
      '&:hover': {
        borderColor: theme.color.textFieldBorderColorHover,
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: theme.color.textFieldTextColorPlaceholder,
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      top: '48px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
      padding: '0 8px 0 8px',
      zIndex: '1',
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '264px',
    }),
    option: (provided: any, state: { isFocused: boolean; isSelected: boolean }) => ({
      ...provided,
      backgroundColor: state.isFocused ? theme.color.textFieldOptionBackgroundColorFocused : theme.color.textFieldOptionBackgroundColor,
      color: state.isFocused ? theme.color.textFieldOptionTextColorFocused : theme.color.textFieldOptionTextColor,
      borderRadius: '8px',
      padding: '8px 12px',
      cursor: 'pointer',
      '&:hover': {
        color: theme.color.textFieldOptionTextColorHover,
        backgroundColor: theme.color.textFieldOptionBackgroundColorHover,
      },
      '&:active': {
        backgroundColor: theme.color.textFieldBackgroundColorActive,
      },
    }),
  };

  const roundedStyles = {
    control: (provided: any) => ({
      ...provided,
      height: '60px',
      border: '0',
      backgroundColor: 'white',
      paddingLeft: '40px',
      borderRadius: '30px',
      boxShadow: 'none',
      zIndex: '2',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: theme.color.textFieldTextColorPlaceholder,
    }),
    indicatorsContainer: () => ({
      display: 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      top: '-8px',
      backgroundColor: 'white',
      borderRadius: '30px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.12)',
      padding: '60px 12px 12px 12px',
      zIndex: '1',
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '264px',
    }),
    option: (provided: any, state: { isFocused: boolean; isSelected: boolean }) => ({
      ...provided,
      backgroundColor: state.isFocused ? theme.color.textFieldOptionBackgroundColorFocused : theme.color.textFieldOptionBackgroundColor,
      color: state.isFocused ? theme.color.textFieldOptionTextColorFocused : theme.color.textFieldOptionTextColor,
      borderRadius: '8px',
      padding: '12px 16px',
      cursor: 'pointer',
      '&:hover': {
        color: theme.color.textFieldOptionTextColorHover,
        backgroundColor: theme.color.textFieldOptionBackgroundColorHover,
      },
      '&:active': {
        backgroundColor: theme.color.textFieldOptionBackgroundColorFocused,
      },
    }),
  };
  return { defaultStyles, roundedStyles };
};
