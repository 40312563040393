import { faBadgeCheck, faHandHoldingSeedling, faHouse, faUserGraduate, faUsers } from '@fortawesome/pro-light-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Button } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import Container from '../../sections/common/Container';
import { CategoriesCMSResult } from '../../types/cms/homepageTypes';
import { LanguageKey } from '../../types/common';
import { getRoute } from '../../utils/languages';

const ContainerWrapper = styled.div`
  > div {
    max-width: 1312px;
  }
`;

const CategoriesWrapper = styled.section`
  border-bottom: 1px solid ${(props) => props.theme.color.gray30};
  padding: 80px 0;
  position: relative;
  z-index: 2;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 52px 51px;
    border-bottom: none;
    background: #f5f7f7;
    border-radius: 8px;
  }
  h2 {
    text-align: center;
    margin-bottom: 48px;
  }
`;

const CategoriesBoxes = styled.div`
  margin-bottom: 24px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    display: flex;
    justify-content: space-between;
  }
`;

const CategoriesBox = styled.div`
  border: 1px solid ${(props) => props.theme.color.gray20};
  background: white;
  border-radius: 8px;
  padding: 40px;
  margin-bottom: 24px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
    flex: 0 0 calc(50% - 24px);
    margin-bottom: 24px;
  }
  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    svg {
      height: 40px;
      margin-right: 16px;
    }
  }
  ul {
    margin-bottom: 32px;
    li {
      margin-bottom: 12px;
      &:last-of-type {
        margin-bottom: 0;
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const CategoriesButtons = styled.ul`
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    margin-bottom: 24px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      flex: 0 0 calc(100% / 2 - 12px);
      margin-right: 24px;
    }
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
      flex: 0 0 calc(100% / 3 - 16px);
      margin-right: 24px;
    }
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) and (max-width: ${(props) => props.theme.breakpoint.lg}) {
      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }
    }
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.lg}) {
      &:nth-of-type(3n + 3) {
        margin-right: 0;
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${(props) => props.theme.color.gray20};
      background: white;
      border-radius: 8px;
      padding: 32px 24px;
      svg {
        height: 32px;
        margin-right: 8px;
      }
      span {
        font-weight: 600;
      }
      &:hover {
        border-color: ${(props) => props.theme.color.gray40};
      }
      &:active {
        background: ${(props) => props.theme.color.gray10};
      }
    }
  }
`;

const Categories = ({ fromCMS }: { fromCMS?: CategoriesCMSResult }) => {
  const { locale } = useRouter();
  return (
    <ContainerWrapper>
      <Container>
        <CategoriesWrapper>
          <Heading size="s" tag="h2">
            {fromCMS?.Headline}
          </Heading>
          <CategoriesBoxes>
            <CategoriesBox>
              <Heading size="xs" tag="h3">
                <FontAwesomeIcon icon={faHouse} />
                <span>{fromCMS?.RentalHousingHeadline}</span>
              </Heading>
              <ul>
                {fromCMS?.RentalHousingLinks.map((item, index) => (
                  <li key={index}>
                    <Link href={item.Link} passHref>
                      <a>{item.Label}</a>
                    </Link>
                  </li>
                ))}
              </ul>
              <Link href={getRoute('/rental-housing', locale as LanguageKey)} passHref>
                <a>
                  <Button rightIcon={faLongArrowRight}>{fromCMS?.RentalHousingCTA.Label}</Button>
                </a>
              </Link>
            </CategoriesBox>
            <CategoriesBox>
              <Heading size="xs" tag="h3">
                <FontAwesomeIcon icon={faBadgeCheck} />
                <span>{fromCMS?.NewBuildHeadline}</span>
              </Heading>
              <ul>
                {fromCMS?.NewBuildLinks.map((item, index) => (
                  <li key={index}>
                    <Link href={item.Link} passHref>
                      <a>{item.Label}</a>
                    </Link>
                  </li>
                ))}
              </ul>
              <Link href={getRoute('/new-build', locale as LanguageKey)} passHref>
                <a>
                  <Button rightIcon={faLongArrowRight}>{fromCMS?.NewBuildCTA.Label}</Button>
                </a>
              </Link>
            </CategoriesBox>
          </CategoriesBoxes>
          <CategoriesButtons>
            <li>
              <Link href={fromCMS?.SeniorCTA.Link || ''} passHref>
                <a>
                  <FontAwesomeIcon icon={faUsers} />
                  <span>{fromCMS?.SeniorCTA.Label}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href={fromCMS?.StudentCTA.Link || ''} passHref>
                <a>
                  <FontAwesomeIcon icon={faUserGraduate} />
                  <span>{fromCMS?.StudentCTA.Label}</span>
                </a>
              </Link>
            </li>
            <li>
              <Link href={fromCMS?.SustainabilityCTA.Link || ''} passHref>
                <a>
                  <FontAwesomeIcon icon={faHandHoldingSeedling} />
                  <span>{fromCMS?.SustainabilityCTA.Label}</span>
                </a>
              </Link>
            </li>
          </CategoriesButtons>
        </CategoriesWrapper>
      </Container>
    </ContainerWrapper>
  );
};

export default Categories;
