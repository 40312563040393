import { faCheckCircle, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Heading } from 'legacy-ui';
import LocationSuggestionBox from '../../components/LocationSuggestionBox';
import { getSearchParamsFromOption } from '../../components/LocationSuggestionBox/functions';
import { Text } from 'legacy-ui';
import Container from '../common/Container';
import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { HeroCMSResult } from '../../types/cms/homepageTypes';
import { LanguageKey } from '../../types/common';
import { getSearchLink } from '../../utils/links';
import React, { Ref } from 'react';

const StyledHero = styled.section`
  position: relative;
  color: white;
  background-color: ${(props) => props.theme.color.gray90};
  padding: 144px 0 64px 0;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding-top: 161px;
  }
  h1 {
    text-align: center;
    margin-bottom: 64px;
    white-space: pre-wrap;
  }
`;

const StyledHeroSearch = styled.div`
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  svg {
    color: ${(props) => props.theme.color.gray90};
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    z-index: 2;
  }
`;

const StyledHeroUsps = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 47px;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    flex-direction: row;
    justify-content: center;
    margin-top: 63px;
  }
  li {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: left;
    margin: 12px 0;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      margin: 0 24px;
      flex-direction: column;
      text-align: center;
    }
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.xl}) {
      flex-direction: row;
      text-align: left;
    }
    svg {
      margin-right: 8px;
      margin-bottom: 0;
      @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
        margin-right: 0;
        margin-bottom: 8px;
      }
      @media only screen and (min-width: ${(props) => props.theme.breakpoint.xl}) {
        margin-right: 8px;
        margin-bottom: 0;
      }
    }
  }
`;

const StyledHeroImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    height: 110%;
  }
`;

const StyledHeroContent = styled.div`
  position: relative;
  z-index: 3;
`;

const HeadingLabel = styled.label`
  display: flex;
  justify-content: center;
  span {
    color: ${(props) => props.theme.color.green40};
  }
  h2 {
    text-align: center;
    max-width: 757px;
    margin-bottom: 47px;
  }
`;
const HeadingTitle = styled.div`
  display: flex;
  justify-content: center;
  h2 {
    margin-bottom: 9px;
  }
`;

interface HeroProps {
  fromCMS?: HeroCMSResult;
  generalTexts: GeneralCMSResult;
}

const Hero = React.forwardRef(({ fromCMS, generalTexts }: HeroProps, ref: Ref<HTMLDivElement>) => {
  const router = useRouter();
  const { locale } = router;

  return (
    <StyledHero ref={ref}>
      {fromCMS && (
        <StyledHeroImage>
          <Image src={fromCMS?.Image.data.attributes.url} alt="Propstep" layout="fill" objectFit="cover" priority />
        </StyledHeroImage>
      )}
      <StyledHeroContent>
        <Container>
          <HeadingTitle>
            <Heading size="xxs" tag="h2">
              {fromCMS?.Title}
            </Heading>
          </HeadingTitle>
          <HeadingLabel htmlFor="react-select-selectId-input">
            <Heading size="r" tag="h2">
              {fromCMS?.Headline} <span>{fromCMS?.HeadlinePart}</span>
            </Heading>
          </HeadingLabel>
          <StyledHeroSearch>
            <LocationSuggestionBox
              onChange={(option) => {
                if (!option) {
                  return;
                }
                const pathname = getSearchLink(locale as LanguageKey);
                const searchParamsFromOption = getSearchParamsFromOption(option);
                const query = {
                  viewportCoordinates: JSON.stringify(searchParamsFromOption),
                  zipCodes: searchParamsFromOption.location?.zipCode,
                };
                router.push({ pathname, query });
              }}
              placeholder={generalTexts.Misc.SearchPlaceholder}
              loadingMessage={generalTexts.Misc.Loading}
              style="rounded"
              groupLabels={{
                city: generalTexts.Misc.City,
                municipality: generalTexts.Misc.Municipality,
                area: generalTexts.Misc.Area,
                property: generalTexts.Misc.PropertySingular,
              }}
            />
            <FontAwesomeIcon icon={faSearch} />
          </StyledHeroSearch>
          <StyledHeroUsps>
            {fromCMS?.USPs?.map((usp: { id: number; Entry: string }) => (
              <li key={usp.id}>
                <FontAwesomeIcon icon={faCheckCircle} />
                <Text weight="m">{usp.Entry}</Text>
              </li>
            ))}
          </StyledHeroUsps>
        </Container>
      </StyledHeroContent>
    </StyledHero>
  );
});

Hero.displayName = 'Hero';

export default Hero;
