import { faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';
import { Text } from 'legacy-ui';

interface AccordionProps {
  items?: AccordionItemProps[];
}

interface AccordionItemProps {
  headline?: string;
  description?: string;
}

const StyledAccordionItem = styled.li<AccordionItemProps>`
  border-bottom: 1px solid ${(props) => props.theme.color.gray30};
  div {
    &:nth-child(1) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 24px 0;
      cursor: pointer;
      p {
        padding-right: 16px;
      }
      svg {
        width: 16px;
        height: 16px;
        margin-top: 6px;
      }
    }
    &:nth-child(2) {
      margin-bottom: 24px;
      p {
        white-space: pre-wrap;
      }
    }
  }
`;

function AccordionItem({ headline: Question, description: Answer }: AccordionItemProps) {
  const [isActive, setIsActive] = useState(false);
  return (
    <StyledAccordionItem>
      <div onClick={() => setIsActive(!isActive)}>
        <Text weight="m">{Question}</Text>
        <FontAwesomeIcon icon={isActive ? faTimes : faPlus} />
      </div>
      {isActive && (
        <div>
          <Text>{Answer}</Text>
        </div>
      )}
    </StyledAccordionItem>
  );
}

function Accordion({ items }: AccordionProps) {
  return (
    <ul>
      {items?.map((item: AccordionItemProps, index: number) => <AccordionItem key={index} headline={item.headline} description={item.description} />)}
    </ul>
  );
}

export default Accordion;
