import { useAtom } from 'jotai';
import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import SEO from '../components/SEO';
import Page from '../sections/common/Page';
import Categories from '../sections/homepage/Categories';
import FAQs from '../sections/homepage/FAQs';
import Header from '../sections/homepage/Header';
import { userState } from '../state/userState';
import { HomepageCMSResult } from '../types/cms/homepageTypes';
import { LanguageKey } from '../types/common';
import { ServerData } from '../types/serverData';
import { QueryParamKeys, QueryParamValues } from '../utils/constants';
import { loadAllDataFromCms } from '../utils/http';
import { getQueryParamsFromUrl, isUrlTrustedDomain } from '../utils/links';
import { getOGImagesFromCMSData } from '../utils/seo';
import Hero from '../sections/homepage/Hero';
import BusinessOverview from '../sections/homepage/BusinessOverview';
import { useWhiteLabel } from '../providers/WhiteLabelProvider';
import styled from 'styled-components';
import { getRoute } from '../utils/languages';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const WhiteLabelContent = styled.div`
  min-height: calc(100vh - 178px);
`;

const Homepage = ({ pageData, headerData, footerData, generalData }: ServerData<HomepageCMSResult>) => {
  const businessSectionRef = useRef<HTMLDivElement>(null);
  const searchSectionRef = useRef<HTMLDivElement>(null);
  const openGraphImages = getOGImagesFromCMSData(pageData, generalData);
  const router = useRouter();
  const { locale } = router;
  const [userAtom, setUserState] = useAtom(userState);
  const { status, user, showLoginPopup } = userAtom;
  const { isWhiteLabel } = useWhiteLabel();
  const queryParams = getQueryParamsFromUrl(router.query);
  const authValue = queryParams.get(QueryParamKeys.Auth);
  const redirectUrlValue = queryParams.get(QueryParamKeys.RedirectUrl);

  function scrollToSection(ref: React.RefObject<HTMLDivElement>) {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    if (Object.keys(router.query).length === 0) {
      return;
    }
    if (status !== 'loaded' && user) {
      return;
    }

    setUserState((previousState) => ({
      ...previousState,
      showLoginPopup: authValue === QueryParamValues.Login,
      onLogin: async () => {
        if (redirectUrlValue && isUrlTrustedDomain(redirectUrlValue)) {
          location.assign(redirectUrlValue);
          return;
        }
        router.back();
      },
    }));
  }, [user, status, router, setUserState, isWhiteLabel]);

  useEffect(() => {
    if (!isWhiteLabel || showLoginPopup) {
      return;
    }
    setUserState((previousState) => ({
      ...previousState,
      showLoginPopup: true,
      onLogin: async () => {
        if (redirectUrlValue && isUrlTrustedDomain(redirectUrlValue)) {
          location.assign(redirectUrlValue);
          return;
        }
        router.push(getRoute('/applications', locale as LanguageKey));
      },
    }));
  }, [isWhiteLabel, showLoginPopup]);

  const getContent = () => {
    if (isWhiteLabel) {
      return <WhiteLabelContent />;
    }
    return (
      <>
        <SEO title={pageData?.SEO.Title} description={pageData?.SEO.Description} images={openGraphImages} />
        <Header
          fromCMS={pageData?.Header}
          scrollToBusinessSection={() => scrollToSection(businessSectionRef)}
          scrollToSearchSection={() => scrollToSection(searchSectionRef)}
        />
        <BusinessOverview fromCMS={pageData?.Business} ref={businessSectionRef} />
        <Hero fromCMS={pageData?.Hero} generalTexts={generalData} ref={searchSectionRef} />
        <Categories fromCMS={pageData?.Categories} />
        <FAQs fromCMS={pageData?.FAQs} />
      </>
    );
  };

  return (
    <Page header={{ options: 'white', data: headerData }} footer={{ options: 'no-border', data: footerData }}>
      {getContent()}
    </Page>
  );
};

export default Homepage;

export async function getServerSideProps({ locale }: GetServerSidePropsContext) {
  const dataPromise = loadAllDataFromCms<HomepageCMSResult>('homepage', locale as LanguageKey, [
    'Hero.USPs',
    'Hero.Image',
    'Business.Images',
    'Header.Image',
    'Categories.RentalHousingLinks',
    'Categories.RentalHousingCTA',
    'Categories.NewBuildLinks',
    'Categories.NewBuildCTA',
    'Categories.SeniorCTA',
    'Categories.StudentCTA',
    'Categories.SustainabilityCTA',
    'FAQs.Questions',
    'SEO.OGImage',
  ]);
  const translationsPromise = serverSideTranslations(locale as LanguageKey);

  const [data, translations] = await Promise.all([dataPromise, translationsPromise]);

  return {
    props: { ...data, ...translations },
  };
}
